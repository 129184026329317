var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm._m(0),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.is_loading,
            expression: "!is_loading",
          },
        ],
        staticClass:
          "text-center justify-center q-pa-md q-gutter-sm fixed-center",
        staticStyle: { "z-index": "1000" },
      },
      [_c("q-spinner-hourglass", { attrs: { size: "4em" } })],
      1
    ),
    _c("div", { staticClass: "row justify-center" }, [
      _c(
        "div",
        { staticClass: "col-12 col-md-4 q-mr-sm" },
        [
          _c("q-input", {
            attrs: { outlined: "", mask: "##/##/####", label: "Dal giorno" },
            scopedSlots: _vm._u([
              {
                key: "append",
                fn: function () {
                  return [
                    _c(
                      "q-icon",
                      {
                        staticClass: "cursor-pointer",
                        attrs: { name: "event" },
                      },
                      [
                        _c(
                          "q-popup-proxy",
                          {
                            ref: "qDateProxy",
                            attrs: {
                              "transition-show": "scale",
                              "transition-hide": "scale",
                            },
                          },
                          [
                            _c(
                              "q-date",
                              {
                                attrs: { mask: "DD/MM/YYYY" },
                                model: {
                                  value: _vm.dal_giorno,
                                  callback: function ($$v) {
                                    _vm.dal_giorno = $$v
                                  },
                                  expression: "dal_giorno",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "row items-center justify-end",
                                  },
                                  [
                                    _c("q-btn", {
                                      directives: [
                                        {
                                          name: "close-popup",
                                          rawName: "v-close-popup",
                                        },
                                      ],
                                      attrs: { label: "Close", flat: "" },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
            model: {
              value: _vm.dal_giorno,
              callback: function ($$v) {
                _vm.dal_giorno = $$v
              },
              expression: "dal_giorno",
            },
          }),
          _c("br"),
          _c("q-input", {
            attrs: { outlined: "", mask: "##/##/####", label: "Al giorno" },
            scopedSlots: _vm._u([
              {
                key: "append",
                fn: function () {
                  return [
                    _c(
                      "q-icon",
                      {
                        staticClass: "cursor-pointer",
                        attrs: { name: "event" },
                      },
                      [
                        _c(
                          "q-popup-proxy",
                          {
                            ref: "qDateProxy",
                            attrs: {
                              "transition-show": "scale",
                              "transition-hide": "scale",
                            },
                          },
                          [
                            _c(
                              "q-date",
                              {
                                attrs: { mask: "DD/MM/YYYY" },
                                model: {
                                  value: _vm.al_giorno,
                                  callback: function ($$v) {
                                    _vm.al_giorno = $$v
                                  },
                                  expression: "al_giorno",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "row items-center justify-end",
                                  },
                                  [
                                    _c("q-btn", {
                                      directives: [
                                        {
                                          name: "close-popup",
                                          rawName: "v-close-popup",
                                        },
                                      ],
                                      attrs: { label: "Close", flat: "" },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
            model: {
              value: _vm.al_giorno,
              callback: function ($$v) {
                _vm.al_giorno = $$v
              },
              expression: "al_giorno",
            },
          }),
          _c("br"),
          _c(
            "div",
            { attrs: { align: "center" } },
            [
              _c("br"),
              _c("q-btn", {
                attrs: {
                  color: "primary",
                  label: "Cerca",
                  disable: _vm.pulsante_rinnovi_disattivato,
                },
                nativeOn: {
                  click: function ($event) {
                    return _vm.onCercaScadenze.apply(null, arguments)
                  },
                },
              }),
              _c("br"),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "col-12 col-md-5 q-mr-sm justify-center" }, [
        _c(
          "fieldset",
          [
            _c("legend", [_vm._v(" Selezione rapida ")]),
            _c("q-btn", {
              attrs: {
                flat: "",
                label: "Questa Settimana",
                icon: "mdi-download-network-outline",
              },
              nativeOn: {
                click: function ($event) {
                  return _vm.onQuestaSettimana.apply(null, arguments)
                },
              },
            }),
            _c("br"),
            _c("q-btn", {
              attrs: {
                flat: "",
                label: "Questo Mese",
                icon: "mdi-download-network-outline",
              },
              nativeOn: {
                click: function ($event) {
                  return _vm.onQuestoMese.apply(null, arguments)
                },
              },
            }),
            _c("br"),
            _c("q-btn", {
              attrs: {
                flat: "",
                label: "Settimana prossima",
                icon: "mdi-download-network-outline",
              },
              nativeOn: {
                click: function ($event) {
                  return _vm.onSettimanaProssima.apply(null, arguments)
                },
              },
            }),
            _c("br"),
            _c("q-btn", {
              attrs: {
                flat: "",
                label: "Mese prossimo",
                icon: "mdi-download-network-outline",
              },
              nativeOn: {
                click: function ($event) {
                  return _vm.onMeseProssimo.apply(null, arguments)
                },
              },
            }),
            _c("br"),
            _c("q-btn", {
              attrs: {
                flat: "",
                label: "Prossimi 15 giorni",
                icon: "mdi-download-network-outline",
              },
              nativeOn: {
                click: function ($event) {
                  return _vm.onProssimi15giorni.apply(null, arguments)
                },
              },
            }),
            _c("br"),
            _c("q-btn", {
              attrs: {
                flat: "",
                label: "Prossimi 30 giorni",
                icon: "mdi-download-network-outline",
              },
              nativeOn: {
                click: function ($event) {
                  return _vm.onProssimi30giorni.apply(null, arguments)
                },
              },
            }),
            _c("br"),
            _c("q-btn", {
              attrs: {
                flat: "",
                label: "Terzo mese successivo (per Disdette)",
                icon: "mdi-download-network-outline",
              },
              nativeOn: {
                click: function ($event) {
                  return _vm.onTerzoMeseSuccessivo.apply(null, arguments)
                },
              },
            }),
          ],
          1
        ),
      ]),
    ]),
    _vm.elenco_contratti_da_lavorare.length === 0
      ? _c("div", { attrs: { align: "center" } }, [
          _c("h5", [_vm._v("Nessuna pratica in scadenza identificata")]),
        ])
      : _vm._e(),
    _vm.elenco_contratti_da_lavorare.length > 0
      ? _c("div", [
          _c("br"),
          _c("table", { staticClass: "table-bordered" }, [
            _vm._m(1),
            _c(
              "tbody",
              _vm._l(
                _vm.elenco_contratti_da_lavorare,
                function (contratto, index) {
                  return _c("tr", { key: index }, [
                    _c("td", [
                      _vm._v(_vm._s(contratto.emittente)),
                      _c("br"),
                      _vm._v(_vm._s(contratto.unita_operativa)),
                      _c("br"),
                      _vm._v(_vm._s(contratto.numero)),
                    ]),
                    _c("td", [_vm._v(_vm._s(contratto.nominativo))]),
                    _c("td", [
                      _vm._v(_vm._s(contratto.compagnia)),
                      _c("br"),
                      _vm._v(_vm._s(contratto.prodotto_id)),
                    ]),
                    _c("td", [_vm._v(_vm._s(contratto.frazionamento))]),
                    _c(
                      "td",
                      { attrs: { align: "center" } },
                      [
                        _c("q-checkbox", {
                          attrs: { size: "lg", val: "lg", disable: "" },
                          model: {
                            value: contratto.tacito_rinnovo,
                            callback: function ($$v) {
                              _vm.$set(contratto, "tacito_rinnovo", $$v)
                            },
                            expression: "contratto.tacito_rinnovo",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "td",
                      [
                        _c("q-checkbox", {
                          attrs: { size: "lg", val: "lg" },
                          model: {
                            value: contratto.disdettato,
                            callback: function ($$v) {
                              _vm.$set(contratto, "disdettato", $$v)
                            },
                            expression: "contratto.disdettato",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "td",
                      [
                        _c("q-checkbox", {
                          attrs: { size: "lg", val: "lg", disable: "" },
                          model: {
                            value: contratto.rinnovato,
                            callback: function ($$v) {
                              _vm.$set(contratto, "rinnovato", $$v)
                            },
                            expression: "contratto.rinnovato",
                          },
                        }),
                      ],
                      1
                    ),
                    _c("td", { attrs: { align: "right" } }, [
                      _vm._v(_vm._s(contratto.premioinscadenza) + " Euro"),
                    ]),
                    _c(
                      "td",
                      [
                        _c("q-input", {
                          attrs: {
                            type: "number",
                            dense: "",
                            outlined: "",
                            label: "Premio di Rinnovo",
                            disable: _vm.isPremioBloccato(contratto),
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "append",
                                fn: function () {
                                  return [_vm._v(" Euro ")]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            true
                          ),
                          model: {
                            value: contratto.premio_rinnovo,
                            callback: function ($$v) {
                              _vm.$set(contratto, "premio_rinnovo", $$v)
                            },
                            expression: "contratto.premio_rinnovo",
                          },
                        }),
                      ],
                      1
                    ),
                  ])
                }
              ),
              0
            ),
          ]),
          _c("br"),
          _c("br"),
          _c(
            "div",
            { attrs: { align: "center" } },
            [
              _c("QQButton", {
                attrs: {
                  label: "Registra rinnovi",
                  color: "blue-grey",
                  icon: "mdi-arrange-send-backward",
                  size: "xl",
                  disable: _vm.pulsante_rinnovi_disattivato,
                },
                nativeOn: {
                  click: function ($event) {
                    return _vm.onRegistraRinnovi.apply(null, arguments)
                  },
                },
              }),
            ],
            1
          ),
          _c("br"),
          _c("br"),
          _c("br"),
          _c("br"),
          _c("br"),
          _c("br"),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { attrs: { align: "center" } }, [
      _c("h4", { staticClass: "q-mb-sm" }, [
        _vm._v("Rinnovo pratiche in scadenza"),
      ]),
      _c("br"),
      _c("br"),
      _c("hr"),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Fornitore/Unità operativa")]),
        _c("th", [_vm._v("Contraente")]),
        _c("th", [_vm._v("Compagnia/Tariffa")]),
        _c("th", [_vm._v("Frazionamento")]),
        _c("th", [_vm._v("Tacito Rinnovo")]),
        _c("th", [_vm._v("Disdetta")]),
        _c("th", [_vm._v("Rinnovata")]),
        _c("th", [_vm._v("Premio in scadenza")]),
        _c("th", [_vm._v("Premio rinnovo")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }